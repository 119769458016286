import React from "react";
import Logo from "../Assets/logoWhite.png";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="FooterContainer">
      <img src={Logo}/>
      <p2>Copyright &#169; 2021</p2>
      </div>
    </div>
  );
};

export default Footer;
